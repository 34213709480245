import React from "react";
import { BsWhatsapp, BsInstagram, BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col">
              <h4 className="text-Dark my-4">Dirección Puebla</h4>
              <div>
                <div className="footer-links d-flex flex-column ">
                  <address className="text-dark">
                    {" "}
                    Ajalpan 16 <br />
                    Villas Atlixcayotl, Puebla, México. <br />
                    Código Postal: 72197
                  </address>
                </div>
              </div>
            </div>
            <div className="col">
              <h4 className="text-Dark my-4">Dirección Los Ángeles</h4>
              <div>
                <div className="footer-links d-flex flex-column ">
                  <address className="text-dark">
                    {" "}
                    5034 Hampton St.
                    <br />
                    Vernon, California.
                    <br />
                    Código Postal: 90058
                  </address>
                </div>
              </div>
            </div>
            <div className="col">
              <h4 className="text-dark my-4">Contacto</h4>
              <div>
                <div className="footer-links d-flex flex-column ">
                  <a
                    href="Tel: +52 221 652 4006"
                    className="mt-2 d-block mb-2 text-dark"
                  >
                    +52 221 652 4006
                  </a>
                  <a
                    href="mailto: todousapuebla@gmail.com"
                    className="mt-2 d-block mb-2 text-dark"
                  >
                    todousapuebla@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className=" d-flex justify-content-center align-items-center gap-30 p-2">
                <a
                  className="px-3"
                  href="https://www.facebook.com/profile.php?id=100093831042060&mibextid=LQQJ4d"
                >
                  <BsFacebook className="text-dark fs-1" to="" />
                </a>
                <a
                  className="px-3"
                  href="https://www.instagram.com/tododeusa_puebla/"
                >
                  <BsInstagram className="text-dark fs-1" to="" />
                </a>
                <a className="px-3" href="https://wa.me/message/TYHVMN5POHSNA1">
                  <BsWhatsapp className="text-dark fs-1" to="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-dark">
                ©{new Date().getFullYear()}: Powered by DV
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
