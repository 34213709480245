import React from "react";

const About = () => {
  return (
    <>
      <body className="home-wrapper-1 py-5">
        <div className="container-xxl py-3">
          <img
            className="home-image"
            src={require("../images/about.jpg")}
            alt=""
          />
        </div>

        <div className="container-xxl py-3">
          <div className="home-jumbotron d-flex justify-content-start px-5">
            <div className="d-flex flex-column text-center p-3">
              {" "}
              <p className="tittle"> ¿Qué es TODO DE USA PUEBLA? </p>
              <p className="bodytext">
                TODO DE USA es tu solución confiable y eficiente para el envío
                de tus productos desde Estados Unidos hasta México. Estamos
                especializados en brindar apoyo a mayoristas, comercios
                electrónicos, pequeños y grandes comerciantes que desean reducir
                sus costos de importacion. <br />
                Nuestro enfoque principal es facilitar la importación de tus
                productos, asegurando que lleguen de manera segura y puntual a
                su destino final. Contamos con una red logística sólida y
                asociaciones estratégicas que nos permiten ofrecerte tarifas
                competitivas y un servicio de calidad. Nuestro equipo
                experimentado está capacitado para manejar todo tipo de
                mercancía, ya sea pequeña o grande, asegurando que se cumplan
                todas las regulaciones aduaneras y requisitos de importación.{" "}
                <br />
                Nuestra prioridad es brindarte un servicio personalizado, ágil y
                transparente, para que puedas enfocarte en hacer crecer tu
                negocio mientras nosotros nos encargamos de la logística. Confía
                en nuestro servicio de paquetería para tus importaciones desde
                Estados Unidos a México. ¡Nosotros nos encargamos de hacer
                llegar tus productos a su destino con profesionalismo y
                puntualidad!
              </p>
              <a
                href="https://wa.me/message/TYHVMN5POHSNA1"
                className="register-button my-2 p-3 d-flex justify-content-center"
              >
                Registrate Ahora
              </a>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default About;
