import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="header-upper-links py-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-md-4">
            <NavLink to={"/"} className="logo">
              <img
                className="logoimage"
                src={require("../images/logo.jpg")}
                alt=""
              />
            </NavLink>
          </div>
          <div className="col-6 col-md-8">
            <nav className="navigation px-5">
              <ul className="nav justify-content-end p-3">
                <li className="nav-item">
                  <NavLink to={"/about"} className="nav-link text-dark">
                    Nosotros
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/contact"} className="nav-link text-dark">
                    Contacto
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/faq"} className="nav-link text-dark">
                    Preguntas Frecuentes
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
